import React from "react";

import CatalogListItem from "../CatalogListItem/CatalogListItem";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const CatalogList = ({ data, addApp, checkDisabled }) => {
  return (
    <ul className="app-list app-list--most-used">
      {data && data.allApplications
        ? data.allApplications.map((app, idx) => (
            <ErrorBoundary>
              <CatalogListItem
                idx={idx}
                app={app}
                addApp={addApp}
                checkDisabled={checkDisabled}
              />
            </ErrorBoundary>
          ))
        : null}
    </ul>
  );
};

export default CatalogList;
