import React from "react";
import styled from "styled-components";

// styles
import "./envlqselector.css";

const DivSpanShow = styled.div`
  padding: 0.8rem 1.6rem;
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background: rgb(0, 0, 0, 0.3);
  color: white;
  border-radius: 0.2rem;
  width: 10rem;
  cursor: pointer;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;

const Selector = styled.select`
  border: 0;
  width: 100%;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: transparent;
  appearance: none;
  position: relative;
  option{
    color: white;
  }
`;

const EnvlqSelector = ({ envlq, setEnvlq }) => {
  const entornos = ["qa", "dev"].includes(process.env.REACT_APP_BRANCH)
    ? ["LQ1", "LQ3"]
    : ["LP1", "LP3"];
  return (
    <DivSpanShow className="divspanShow">
      <Selector onChange={setEnvlq} value={envlq} className="selectorEnvlq">
        {entornos.length > 0
          ? entornos.map((e) => (
              <option value={e} key={e}>
                {e}
              </option>
            ))
          : null}
      </Selector>
      <div>&#9660;</div>
    </DivSpanShow>
  );
};

export default EnvlqSelector;
