import React from "react";

// services
import { isLoggedIn } from "../services/auth";

// components
import Header from "./Header";
import Login from "./Login/Login";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";

const Layout = ({ children, pageName }) => {
  return (
    <>
      {isLoggedIn() ? (
        <div className="l-content">
          <ErrorBoundary>
            <Header pageName={pageName} />
          </ErrorBoundary>
          <div className="l-content__wrapper">
            <div className="row">{children}</div>
          </div>
        </div>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Layout;
