import React from "react";
import { GoogleLogin } from "react-google-login";
import styled from "styled-components";

// images
import Google from "../../images/Google.png";

const Btn = styled.button`
  width: 20rem;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background: white;
  color: black;
  padding: 1rem;
  cursor: pointer;
  border-radius: 4px;
  span{
    font-family: Arial, Helvetica, sans-serif !important;
  }
  :hover {
    background: rgb(0, 0, 0, 0.3);
    color: white;
  }
  :active {
    background: rgb(0, 0, 0, 0.3);
    color: white;
  }
  :focus {
    background: rgb(0, 0, 0, 0.3);
    color: white;
  }
  ::selection {
    background: #0073af;
  }
`;

const Img = styled.img`
  width: 20px;
`;

const GoogleLoginBtn = ({
  envlq,
  googleClientId,
  handleClick,
  handleFailure,
}) => {
  return (
    <GoogleLogin
      clientId={googleClientId}
      // hostedDomain="lafargeholcim.com"
      render={(renderProps) => (
        <Btn
          className="btn_login"
          onClick={renderProps.onClick}
          disabled={false}
          size="large"
          id="login"
        >
          <Img
            alt="googleIcon"
            title="googleIcon"
            className="googleIcon"
            src={Google}
          />
          <span>Login With Google</span>
        </Btn>
      )}
      buttonText="Login"
      onSuccess={(response) => handleClick(response, envlq)}
      onFailure={() => handleFailure()}
      cookiePolicy={"single_host_origin"}
    />
  );
};

export default GoogleLoginBtn;
