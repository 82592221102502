import React from "react"

// styles
import "./logintitle.css"

const LoginTitle = ({ title, corporativeName }) => {
  return (
    <div className="loginTitleContainer">
      <h1 className="loginTitle">
        {corporativeName} {title}
      </h1>
    </div>
  )
}

export default LoginTitle
