import React from "react";

const WelcomeMsg = ({ userData }) => {
  return (
    <div className="content-tools__left">
      <div className="welcome-msg">
        Welcome again,{" "}
        <span className="welcome-msg__name">
          {userData && userData.name
            ? userData.name
            : userData.firstName
            ? userData.firstName
            : null}
          .
        </span>
      </div>
    </div>
  );
};

export default WelcomeMsg;
