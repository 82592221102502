import React from "react";

// images
// import Logo from "img/logo_white.png"

const LoginLogo = () => {
  return (
    <div className="loginLogo">
      {/* <Logo /> */}
      <img src="img/logo_white.png" alt="logo" width="250px" />
    </div>
  );
};

export default LoginLogo;
