import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { logout } from "../services/auth";

function Nav(props) {
  const getNavLinkClass = (path) => {
    return props.location.pathname === path ? "is-active" : "";
  };

  const closeMenu = () => {
    const app = document.querySelector(".l-app");

    app.classList.toggle("menu-is-opened");
  };

  return (
    <header className="l-header">
      <button onClick={closeMenu} className="btn-menu">
        <i className="zmdi zmdi-menu"></i>
      </button>
      <div className="logo">
        <svg
          width="98px"
          height="52px"
          viewBox="0 0 98 52"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Holcim_logo</title>
        </svg>
      </div>
      <img src="img/logo.png" alt="Logotipo de Holcim" />
      <ul className="l-header__nav">
        <li className={getNavLinkClass("/home")}>
          <button className="btn-unstyled">
            <NavLink
              to="/home"
              activeStyle={{ color: "#000000" }}
              style={{ textDecoration: "none", color: "#7b8492" }}
            >
              <i className="zmdi zmdi-home"></i>
              <span>Home</span>
            </NavLink>
          </button>
        </li>

        <li className={getNavLinkClass("/catalog")}>
          <button className="btn-unstyled">
            <NavLink
              to="/catalog"
              activeStyle={{ color: "#000000" }}
              style={{ textDecoration: "none", color: "#7b8492" }}
            >
              <i className="zmdi zmdi-view-list"></i>
              <span>Apps catalog</span>
            </NavLink>
          </button>
        </li>
        <li className={getNavLinkClass("/profile")}>
          <button className="btn-unstyled">
            <NavLink
              to="/profile"
              activeStyle={{ color: "#000000" }}
              style={{ textDecoration: "none", color: "#7b8492" }}
            >
              <i className="zmdi zmdi-account"></i>
              <span>Profile</span>
            </NavLink>
          </button>
        </li>
        <li className={getNavLinkClass("/settings")}>
          <button className="btn-unstyled">
            <NavLink
              to="/settings"
              activeStyle={{ color: "#000000" }}
              style={{ textDecoration: "none", color: "#7b8492" }}
            >
              <i className="zmdi zmdi-settings"></i>
              <span>Settings</span>
            </NavLink>
          </button>
        </li>

        <li>
          <button onClick={() => logout()} className="btn-unstyled">
            <i className="zmdi zmdi-sign-in"></i>
            <span>Log out</span>
          </button>
        </li>
      </ul>
    </header>
  );
}

export default withRouter(Nav);
