import React, { useState, useEffect, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import { getUserApps, getAllAppsByRole, addApplication } from "../graphql";

import Tools from "components/Tools";
import Layout from "components/Layout";
import CatalogList from "components/CatalogList/CatalogList";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import Select from "components/Select/Select";

let applications = [];

const Catalog = () => {
  const [listmode, setListmode] = useState("grid");
  const [order, setorder] = useState("id_ASC");

  const roles = JSON.parse(sessionStorage.getItem("roles"));
  const roleID = roles && roles.length > 0 ? roles[0]?.ROLE_ID : null;
  const roleList = roleID ? [roleID] : [];

  let userData = {};
  const [loadGreeting, { called, loading, error, data }] = useLazyQuery(
    getAllAppsByRole,
    {
      fetchPolicy: "network-only",
      variables: {
        order: "id_DESC",
        functions: roleList,
      },
    }
  );
  console.error(error);

  const [loadUser, result] = useLazyQuery(getUserApps, {
    id: sessionStorage.getItem("userId"),
    fetchPolicy: "network-only",
    variables: { order: "id_DESC" },
  });
  const [addNewApp, resultAddApp] = useMutation(addApplication, {
    refetchQueries: [
      {
        query: getUserApps,
        variables: { id: sessionStorage.getItem("userId"), order: "id_DESC" },
      },
    ],
    awaitRefetchQueries: true,
  });

  const getdata = useCallback(() => {
    const userId = sessionStorage.getItem("userId");
    loadUser({ variables: { id: userId, order: "id_DESC" } });
    loadGreeting();
  }, [loadUser, loadGreeting]);

  useEffect(() => {
    getdata();
  }, [getdata]);

  if (result.data) {
    applications = [];
    userData = result.data;
    for (let i = 0; i < userData.User.applications.length; i++) {
      applications.push({ id: userData.User.applications[i].id });
    }
  }

  if (error) return `Error: ${error}`;
  const changeOrder = (event) => {
    setorder(event.target.value);
    loadGreeting({ variables: { order: event.target.value } });
  };
  const addApp = (event) => {
    if (Object.keys(userData).length > 0) {
      const userId = sessionStorage.getItem("userId");

      const newApp = { id: event.target.id };
      applications.push(newApp);
      addNewApp({
        variables: { identity: userId, applications: applications },
      });
      event.target.disabled = "disabled";
    }
  };
  /* TODO: Move logic to TOOLS Component */
  const changeSearch = (event) => {
    const searchParams = event.target.value;
    if (searchParams.length > 2) {
      loadGreeting({ variables: { search: searchParams } });
    } else if (searchParams == "" || searchParams == null) {
      loadGreeting();
    }
  };
  const listChange = (event) => {
    if (listmode == "grid") {
      document.body.className = "is-list-mode";
      setListmode("list");
    } else {
      document.body.className = "";
      setListmode("grid");
    }
  };
  const checkDisabled = (id) => {
    for (let i = 0; i < applications.length; i++) {
      console.log("USer APPS:", applications);
      if (applications[i].id == id) {
        console.log("igual");
        return "disabled";
      }
    }
    return false;
  };

  let zondaURL = process.env.REACT_APP_ZONDA_ENDPOINT;
  const environment = sessionStorage.getItem("environment");
  if (environment == "LP3") zondaURL = process.env.REACT_APP_ZONDA_ENDPOINT_LP3;

  return (
    <Layout pageName="Catalog">
      <section className="l-section">
        <div className="content-tools">
          <div className="content-tools__left">
            <ErrorBoundary>
              <Select handleChange={changeOrder} value={order} />
            </ErrorBoundary>
          </div>
          <ErrorBoundary>
            <CatalogList>
              <ErrorBoundary>
                <Tools
                  changeSearch={changeSearch}
                  listChange={listChange}
                  listmode={listmode}
                />
              </ErrorBoundary>
            </CatalogList>
          </ErrorBoundary>
        </div>
        <h2 className="l-section__title">Apps available for you</h2>
        <p className="l-section__desc">
          These are all the apps available according to your role settings. Add
          apps to your home screen for a quick access to them.
        </p>
        {error ? <span>{error}</span> : null}
        <ErrorBoundary>
          <CatalogList
            data={data}
            addApp={addApp}
            checkDisabled={checkDisabled}
          />
        </ErrorBoundary>
        {/* </lh-collapse-apps> */}
      </section>
      <section className="l-section">
        <h2 className="l-section__title">Are you missing any app?</h2>
        <p className="l-section__desc">
          Access to Logon where you can see all other funcionalities available.
        </p>
        <div>
          <a href={zondaURL}>
            <lh-button>View Logon</lh-button>
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Catalog;
