import React from "react";
import Moment from "moment";

const HomeAppsUsed = ({ localUsedApps, openApp }) => {
  return (
    <div className="l-section">
      <h2 className="l-section__title">My most-used apps</h2>
      <p className="l-section__desc">
        Here you'll find the most-used apps to provide you quick access to them.
      </p>

      <ul className="app-list app-list--most-used">
        {localUsedApps &&
          Object.keys(localUsedApps).map((key, idx) => (
            <li key={idx}>
              <div
                className="card-content"
                onClick={() =>
                  openApp(localUsedApps[key].data.link, localUsedApps[key].data)
                }
              >
                <lh-card
                  summary={localUsedApps[key].data.name}
                  icon="open-in-new"
                >
                  <div className="card-content__text">
                    <p>{localUsedApps[key].data.description}</p>
                    {/* <lh-button id={localUsedApps[key].data.id} onClick={remove} style={{paddingTop: "10px"}} >Remove</lh-button> */}
                  </div>

                  <ul className="tag-list">
                    {localUsedApps[key].data.category.map((category, ids) => (
                      <li key={ids}>
                        <div className="tag">{category.name}</div>
                      </li>
                    ))}
                  </ul>
                </lh-card>
              </div>
              <div className="app-list__last-date">
                {Moment(localUsedApps[key].data.date).format(
                  "dddd, MMM Do YYYY"
                )}
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default HomeAppsUsed;
