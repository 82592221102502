import { client, listRolesMysql } from "./queries";

export const isBrowser = () => typeof window !== "undefined";

export const handleLogin = (user, envlq) => {
  const token_id = user.tokenObj.id_token;
  let url_auth = `https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${token_id}`;
  fetch(url_auth)
    .then((response) => response.json())
    .then(async (data) => {
      console.log(data);
      console.log(user.tokenObj);
      if (
        [
          "lafargeholcim.com",
          "holcim.com",
          "lafarge.com",
          "geocycle.com",
          "aggregate.com",
        ].includes(data.hd)
      ) {
        client
          .query({
            query: listRolesMysql,
            variables: { userEmail: data.email, envlq: "LQ1" },
          })
          .then((result) => {
            console.log(result);
            console.log(result.data);
            sessionStorage.setItem(
              "roles",
              JSON.stringify(result.data.listRolesMysql)
            );

            // sessionStorage.setItem("region", "nge");

            let userData = {
              name: data.name,
              locale: data.locale,
              picture: data.picture,
              email: data.email,
              secret: user.tokenObj.login_hint,
            };

            sessionStorage.setItem("token", user.tokenObj.access_token);
            sessionStorage.setItem("userData", JSON.stringify(userData));

            window.location.href = "/home";
          });
      }
    })
    .catch((e) => {
      console.error(e);
    });
  return false;
};

// get lang from session storage
export const getLang = () => {
  return isBrowser() && window.sessionStorage.getItem("lang")
    ? window.sessionStorage.getItem("lang")
    : "en";
};

// get first letter name from session storage
export const getLetter = () => {
  return isBrowser() && window.sessionStorage.getItem("lt")
    ? window.sessionStorage.getItem("lt")
    : "";
};

// get region from storage
export const getRegion = () => {
  return isBrowser() && window.sessionStorage.getItem("region")
    ? window.sessionStorage.getItem("region")
    : "standard_version";
};

// if login failed return false
export const handleFailure = () => {
  return false;
};

// check if user is logging by token save in session storage
export const isLoggedIn = () => {
  return isBrowser() && window.sessionStorage.getItem("token") ? true : false;
};

// list language
export const listLanguages = [
  "ar",
  "az",
  "bg",
  "cs",
  "de",
  "el",
  "en",
  "es",
  "fr",
  "hr",
  "hu",
  "it",
  "nl",
  "pl",
  "ro",
  "ru",
  "sk",
  "sl",
  "sr",
  "tr",
  "uk",
];

export const logout = () => {
  sessionStorage.clear();
  localStorage.clear();

  //navigate("/")
  window.location.href = "/";
  return false;
};
