import React from "react";

const SectionCard = ({ children, summary, size }) => {
  return (
    <div className="l-section">
      <lh-card summary={summary} size={size}>
        {children}
      </lh-card>
    </div>
  );
};

export default SectionCard;
