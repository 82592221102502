import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// services

// images
import ArrowBack from "./ArrowBack";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: flex;
  width: 20rem;
`;

const Label = styled.label`
  width: 100%;
  color: #fff;
  :first-child {
    margin-bottom: 1rem;
  }
`;
const Input = styled.input`
  box-sizing: border-box;
  list-style: none;
  padding: 4px 11px;
  touch-action: manipulation;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  color: black;
`;

const BottomArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 20rem;
  align-items: center;
`;

const Btn = styled.button`
  display: flex;
  justify-content: center;
  width: 8rem !important;
`;

const BtnArrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  width: auto !important;
`;

const FormLogin = ({
  goBack,
  envlq,
  regiones,
  title,
  func,
  apiKeyExternal,
}) => {
  // STATE
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const handleLoginExternal = () => {
    fetch(process.env.REACT_APP_EXTERNAL_LOGIN_ENDPOINT, {
      method: "POST",
      headers: { "x-api-key": process.env.REACT_APP_EXTERNAL_LOGIN_APIKEY },
      body: JSON.stringify({
        username: email,
        password: password,
        envlq: envlq,
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.status === 200) {
          console.log("logged IN", data);
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("userData", JSON.stringify(data.body));

          window.location = "/home";
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <>
      <Form>
        <FormGroup>
          <Label>
            Username:
            <Input
              placeholder="Type your username"
              type="text"
              id="login_user"
              required
              onChange={(e) => setemail(e.target.value)}
            />
          </Label>
        </FormGroup>
        <FormGroup>
          <Label>
            Password:
            <Input
              placeholder="Type your password"
              type="password"
              id="login_password"
              required
              onChange={(e) => setpassword(e.target.value)}
            />
          </Label>
        </FormGroup>
      </Form>
      <BottomArea>
        <BtnArrow onClick={() => goBack()}>
          <ArrowBack stroke="#fff" />
        </BtnArrow>
        <Btn onClick={handleLoginExternal}>Enter</Btn>
      </BottomArea>
    </>
  );
};

FormLogin.propTypes = {
  envlq: PropTypes.string,
  goBack: PropTypes.func,
};

export default FormLogin;
