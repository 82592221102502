import React, { useState } from "react";
import Moment from "moment";

function News(props) {
  return (
    <lh-collapse className="news" summary="News" open>
      <ul className="news__list">
        {props.data &&
          props.data.allPosts.map((post) => (
            <li>
              <div className="news__text">
                <strong>{post.title}</strong> {post.description}
              </div>
              <div className="news__date">
                {Moment(post.CreatedAt).format("dddd, MMM Do YYYY")}
              </div>
              <div className="news__action">
                <a href={post.link} className="link">
                  Read more
                </a>
              </div>
            </li>
          ))}
      </ul>
      <a href="#" className="link news__btn-more">
        See all news
      </a>
    </lh-collapse>
  );
}

export default News;
