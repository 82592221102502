import React from "react";

const Option = ({ value, title, disabled }) => {
  return (
    <>
      {disabled ? (
        <option disabled>{title}</option>
      ) : (
        <option value={value}>{title}</option>
      )}
    </>
  );
};

export default Option;
