import React from "react";

const CatalogListItem = ({ idx, app, addApp, checkDisabled }) => {
  return (
    <li key={idx}>
      <lh-card summary={app.name}>
        <div className="card-content">
          <div className="card-content__text">
            <p>{app.description}</p>
          </div>
          <ul className="tag-list">
            {app.category.map((category, idm) => (
              <li key={idm}>
                <div className="tag">{category.name}</div>
              </li>
            ))}
          </ul>
          <div className="card-content__action">
            <lh-button
              id={app.id}
              onClick={addApp}
              disabled={checkDisabled(app.id)}
            >
              Add
            </lh-button>
            <a href={app.link}>
              <lh-button type="primary">Open app</lh-button>
            </a>
          </div>
        </div>
      </lh-card>
    </li>
  );
};

export default CatalogListItem;
