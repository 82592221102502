import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery, gql } from "@apollo/client";

import { client, getUsersList, getTotalCount, getUserApps } from "../graphql";

import Layout from "components/Layout";

function Profile() {
  const [listmode, setListmode] = useState("grid");
  //const [roles, setRoles] = useState([]);
  const roles = JSON.parse(sessionStorage.getItem("roles"));
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  useEffect(() => {
    document.getElementById("page").classList = "l-profile l-app";
    //setRoles(sessionStorage.getItem("roles"));
    console.log(roles);
    loadGreeting();
  }, []);

  const [loadGreeting, { called, loading, error, data }] = useLazyQuery(
    getUserApps,
    { variables: { id: sessionStorage.getItem("userId"), order: "id_DESC" } }
  );
  if (error) return `Error: ${error}`;

  return (
    <Layout pageName="Profile">
      <div className="l-section">
        <lh-card summary="Your personal info" size="large">
          <div className="profile-data">
            <div className="profile-data__pic">
              <div className="profile-pic">
                <img width="100%" src={userData && userData.picture} />
              </div>
            </div>
            <div className="profile-data__info">
              <div className="profile-data__header">
                <div className="profile-data__user-name">
                  {data && data.User.name}
                </div>
                {/* <lh-button-icon name="edit" class="profile-data__btn-edit"></lh-button-icon> */}
              </div>
              {/* <div class="profile-data__user-title">Business Information Services</div> */}
              <ul className="profile-data__others">
                <li>
                  <div className="profile-data__info-title">Name</div>
                  <div className="profile-data__info-text">
                    {data && data.User.name}
                  </div>
                </li>
                <li>
                  <div className="profile-data__info-title">Role</div>
                  <div className="profile-data__info-text">
                    {roles && roles[0].ROLE_NAME}
                  </div>
                </li>
                <li>
                  <div className="profile-data__info-title">Email address</div>
                  <div className="profile-data__info-text">
                    {data && data.User.email}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </lh-card>
      </div>
    </Layout>
  );
}

export default Profile;
