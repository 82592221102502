import React from "react";

// components
import Option from "./Option";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const Select = ({ handleChange, value }) => {
  const options = [
    { value: "id_ASC", title: "None" },
    { value: "name_ASC", title: "Order by name - ASC" },
    { value: "name_DESC", title: "Order by name - DESC" },
  ];
  return (
    <div className="select sort-by">
      <select name="sort-by" onChange={handleChange} value={value}>
        <ErrorBoundary>
          <Option disabled={true} title="Sort by" />
        </ErrorBoundary>
        <ErrorBoundary>
          {options.map((option) => (
            <Option value={option.value} title={option.title} key={option.value} />
          ))}
        </ErrorBoundary>
      </select>
    </div>
  );
};

export default Select;
