import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

// graphql
import {
  getUserApps,
  signIn,
  registerNewUser,
  updateUserAccess,
  getAllNews,
  addApplication,
} from "../graphql";

// components
import News from "components/News";
import Tools from "components/Tools";
import Layout from "components/Layout";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import SecurityMsg from "components/SecurityMsg/SecurityMsg";
import HomeAppsUsed from "components/HomeAppsUsed/HomeAppsUsed";
import HomeAppsAdded from "components/HomeAppsAdded/HomeAppsAdded";
import WelcomeMsg from "components/WelcomeMsg/WelcomeMsg";

function Home() {
  const [listmode, setListmode] = useState("grid");
  const [lastOnline, setLastOnline] = useState();
  const [err, seterr] = useState(null);

  let date = "";
  let localUsedApps = sessionStorage.getItem("mostUsedApps1")
    ? JSON.parse(sessionStorage.getItem("mostUsedApps1"))
    : {};
  let prefs = JSON.parse(sessionStorage.getItem("zondapreferences1"));
  const userData = sessionStorage.getItem("userData")
    ? JSON.parse(sessionStorage.getItem("userData"))
    : {};

  const [loginIn, result] = useMutation(signIn, {
    variables: {
      identity: userData.email ? userData.email : userData.username,
      secret: userData.secret
        ? btoa(userData.name)
        : "000000" + userData.userId.toString(),
    },
  });
  const [registerUser, registerCallback] = useMutation(registerNewUser, {
    variables: {
      id: userData.email ? userData.email : userData.username,
      secret: userData.secret
        ? btoa(userData.name)
        : "000000" + userData.userId.toString(),
      name: userData.name ? userData.name : userData.firstName,
    },
  });

  const [loadGreeting, { called, loading, error, data }] = useLazyQuery(
    getUserApps,
    { variables: { id: sessionStorage.getItem("userId"), order: "id_DESC" } }
  );
  const [getNews, news] = useLazyQuery(getAllNews, {
    variables: { order: "id_DESC" },
  });
  const [updateUser, updated] = useMutation(updateUserAccess, {
    variables: { id: sessionStorage.getItem("userId") },
  });
  const [removeApp, resultAddApp] = useMutation(addApplication, {
    refetchQueries: [
      {
        query: getUserApps,
        variables: { id: sessionStorage.getItem("userId"), order: "id_DESC" },
      },
    ],
    awaitRefetchQueries: true,
  });
  useEffect(() => {
    window.addEventListener("lhInput", changeSearch);
    // if(!isLoggedIn()) {
    loginIn()
      .then((login) => {
        console.log("Loginin", login);
        setLastOnline(login.data.authenticate.item.lastOnline);
        sessionStorage.setItem("token", login.data.authenticate.token);
        sessionStorage.setItem("userId", login.data.authenticate.item.id);
        updateUser({
          variables: {
            id: login.data.authenticate.item.id,
            access: new Date().toISOString(),
          },
        });
        getNews();

        loadGreeting();

        document.getElementById("page").classList = "l-home l-app";

        return () => window.removeEventListener("blur", changeSearch);
      })
      .catch((e) => {
        console.error(e);
        seterr(e);
        registerUser()
          .then((user) => {
            loginIn()
              .then((login) => {
                sessionStorage.setItem("token", login.data.authenticate.token);
                sessionStorage.setItem(
                  "userId",
                  login.data.authenticate.item.id
                );
                updateUser({
                  variables: {
                    id: login.data.authenticate.item.id,
                    access: new Date().toISOString(),
                  },
                });
                getNews();

                loadGreeting();

                document.getElementById("page").classList = "l-home l-app";

                return () => window.removeEventListener("blur", changeSearch);
              })
              .catch((err) => {
                console.error(err);
                seterr(err);
              });
          })
          .catch((err) => {
            console.error("Error Register", err);
            seterr(`Error Register, ${err}`);
          });
      });
  }, []);
  if (error || err) return <span>Error: {error || err}</span>;

  const changeOrder = (event) => {
    loadGreeting({ variables: { order: event.target.value } });
  };

  /* TODO: Move logic to TOOLS Component */
  const changeSearch = (event) => {
    const searchParams = event.target.value;
    if (searchParams.length > 2) {
      loadGreeting({ variables: { search: searchParams } });
    } else if (!searchParams) {
      loadGreeting();
    }
  };

  const listChange = (event) => {
    if (listmode === "grid") {
      document.body.className = "is-list-mode";
      setListmode("list");
    } else {
      document.body.className = "";
      setListmode("grid");
    }
  };
  const openApp = (link, app) => {
    const now = Date.now();

    if (localUsedApps[app.id]) {
      let count = localUsedApps[app.id].count;
      localUsedApps[app.id] = { data: app, count: count + 1, date: now };
    } else {
      localUsedApps[app.id] = { data: app, count: 1, date: now };
    }

    sessionStorage.setItem("mostUsedApps1", JSON.stringify(localUsedApps));
    window.location.href = link;
  };

  return (
    <Layout pageName="Home">
      <ErrorBoundary>
        <SecurityMsg lastOnline={lastOnline} />
        {error || err ? <span>{error || err}</span> : null}
      </ErrorBoundary>

      <div className="l-section">
        <div className="content-tools">
          <ErrorBoundary>
            <WelcomeMsg userData={userData} />
          </ErrorBoundary>
          <ErrorBoundary>
            <Tools
              changeSearch={changeSearch}
              listChange={listChange}
              listmode={listmode}
            />
          </ErrorBoundary>
        </div>
        {news && (
          <ErrorBoundary>
            <News data={news.data} />
          </ErrorBoundary>
        )}
      </div>
      {/* Apps most used */}
      <ErrorBoundary>
        <HomeAppsUsed localUsedApps={localUsedApps} openApp={openApp} />
      </ErrorBoundary>
      {/* End of app most used */}
      {/* My added apps */}
      <ErrorBoundary>
        <HomeAppsAdded
          localUsedApps={localUsedApps}
          openApp={openApp}
          removeApp={removeApp}
          data={data}
        />
      </ErrorBoundary>
      {/* End of my added apps */}
    </Layout>
  );
}

export default Home;
