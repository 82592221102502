import React from "react";
import styled from "styled-components";

// images
import External from "./icon-login-blue.png";

const Btn = styled.button`
  width: 20rem;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background: white;
  color: black;
  padding: 1rem;
  cursor: pointer;
  border-radius: 4px;
  :hover {
    background: rgb(0, 0, 0, 0.3);
    color: white;
  }
  :active {
    background: rgb(0, 0, 0, 0.3);
    color: white;
  }
  :focus {
    background: rgb(0, 0, 0, 0.3);
    color: white;
  }
  ::selection {
    background: #0073af;
  }
`;

const ExternalLogin = ({ handleClick }) => {
  return (
    <Btn
      type="primary"
      size="large"
      id="externalLoginButton"
      onClick={() => handleClick(true)}
    >
      <img
        alt="googleIcon"
        title="googleIcon"
        className="googleIcon"
        src={External}
      />
      External Login
    </Btn>
  );
};

export default ExternalLogin;
