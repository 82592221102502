import React from "react";

const Tools = ({ changeSearch, listChange, listmode }) => {
  return (
    <div className="content-tools__right">
      <lh-input
        type="search"
        lhChange={changeSearch}
        className="content-tools__search"
        name="search"
        placeholder="Search by name, type or category"
      />

      <div className="btn-radio-group content-tools__view">
        <div className="btn-radio btn-radio--icon">
          <input
            type="radio"
            onChange={listChange}
            name="view-mode"
            id="view-mode-0"
            value="0"
            checked={listmode === "list"}
          />
          <label htmlFor="view-mode-0">
            <lh-icon name="view-list-alt" />
          </label>
        </div>
        <div className="btn-radio btn-radio--icon">
          <input
            type="radio"
            onChange={listChange}
            name="view-mode"
            id="view-mode-1"
            value="1"
            checked={listmode === "grid"}
          />
          <label htmlFor="view-mode-1">
            <lh-icon name="view-module" />
          </label>
        </div>
      </div>
    </div>
  );
};

export default Tools;
