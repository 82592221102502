import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { getUserApps } from "../graphql";

import Layout from "components/Layout";
import SectionCard from "components/SectionCard/SectionCard";

function Settings() {
  const [loadGreeting, { called, loading, error, data }] = useLazyQuery(
    getUserApps,
    { variables: { id: sessionStorage.getItem("userId"), order: "id_DESC" } }
  );

  useEffect(() => {
    document.getElementById("page").classList = "l-profile l-app";
    loadGreeting();
  }, [loadGreeting]);

  if (error) return `Error: ${error}`;

  const toggleShowUsed = () => {
    let localPrefs = JSON.parse(sessionStorage.getItem("zondapreferences1"));
    let prefs = localPrefs ? localPrefs : {};
    prefs.viewUsed = prefs.viewUsed ? "false" : "true";

    sessionStorage.setItem("zondapreferences1", JSON.stringify(prefs));
  };

  return (
    <Layout pageName="Settings">
      <SectionCard summary="General settings" size="large">
        <div className="form-item">
          <label className="form-item__title" htmlFor="quantity">
            Language
          </label>
          <div className="form-item__element">
            <div className="select">
              <select defaultValue="0">
                <option value="0">English (US)</option>
              </select>
            </div>
          </div>
        </div>

        <div className="form-item">
          <label className="form-item__title" htmlFor="quantity">
            Time zone
          </label>
          <div className="form-item__element">
            <div className="select">
              <select defaultValue="0">
                <option value="0">(GMT -05:00) Eastern Time - New York</option>
              </select>
            </div>
          </div>
        </div>
      </SectionCard>
      <SectionCard summary="Home settings" size="large">
        <div className="form-item">
          <div className="form-item__element">
            <lh-checkbox name="show-most-used" checked onClick={toggleShowUsed}>
              Show most-used apps
            </lh-checkbox>
          </div>
        </div>
      </SectionCard>
    </Layout>
  );
}

export default Settings;
