import React, { useState } from "react";
import styled from "styled-components";

// services
import { handleLogin, handleFailure, isBrowser } from "../../services/auth";
import { useCookies } from "react-cookie";

// components
import EnvlqSelector from "../EnvlqSelector/EnvlqSelector";
import LoginTitle from "./LoginTitle/LoginTitle";
import LoginLogo from "./LoginLogo/LoginLogo";
import LoginFooter from "./LoginFooter/LoginFooter";
import GoogleLoginBtn from "../GoogleLoginBtn/GoogleLoginBtn";
import FormLogin from "./FormLogin";
import ExternalLoginBtn from "./ExternalLoginBtn";

// styles
import "./Login.css";

const LoginButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Login = () => {
  const [envlq, setEnvlq] = useState("");
  const [form, setform] = useState(false);
  const [cookies, setCookie] = useCookies([]);
  const onChangeCookie = (response, envlq) => {
    setCookie("name", envlq, { path: "/", domain: ".zonda.lafargeholcim.com" });
    handleLogin(response, envlq);
    console.log(cookies);
  };
  const returnLogin = (bool) => {
    setform(bool);
  };
  const changeEnv = (env) => {
    setEnvlq(env.target.value);
    console.log("env", env.target.value);
    sessionStorage.setItem("environment", env.target.value);
  };

  return (
    <div className="login">
      <div className="content_login">
        <LoginTitle title="portal" corporativeName="Zonda" />
        <LoginLogo />
        <LoginButtonContainer className="loginButtonContainer">
          <EnvlqSelector setEnvlq={changeEnv} envlq={envlq} />
          {!form ? (
            <>
              <GoogleLoginBtn
                handleFailure={handleFailure}
                handleClick={onChangeCookie}
                envlq={envlq}
                googleClientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              />

              <ExternalLoginBtn handleClick={setform} />
            </>
          ) : null}
          {form && <FormLogin envlq={envlq} goBack={returnLogin} />}
        </LoginButtonContainer>
      </div>
      <LoginFooter />
    </div>
  );
};

export default Login;
