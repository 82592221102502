import React from "react";

function Header(props) {
  return (
    <div className="l-content__header">
      <div className="row">
        <div className="logo-and-section">
          <div className="logo-zonda">
            <img src="img/logo-zonda.svg" alt="Logotipo de Zonda" />
          </div>
          <h1 className="l-content__title">{props.pageName}</h1>
        </div>
        <div className="l-content__header-action">
          <lh-button-icon name="help" type="ghost" color="white" />
        </div>
      </div>
    </div>
  );
}

export default Header;
