import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ReactDOM from "react-dom";
import { CookiesProvider } from "react-cookie";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/client";

import * as serviceWorker from "./serviceWorker";

// services
import { isLoggedIn } from "services/auth";

// pages
import Home from "pages/Home";
import Catalog from "pages/Catalog";
import Profile from "pages/Profile";
import Settings from "pages/Settings";

// components
import Nav from "components/Nav";
import Login from "components/Login/Login";

// styles
import "./index.css";

const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_ENDPOINT,
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.error(graphQLErrors);
    }
    if (networkError) {
      console.error("network");
    }
  },
});

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      {isLoggedIn() ? (
        <ApolloProvider client={client}>
          <BrowserRouter>
            <div id="page" className="l-app">
              <Nav />
              <Redirect from="/" to="/home" />
              <Switch>
                <Route path="/home" component={Home} />
                <Route path="/catalog" component={Catalog} />
                <Route path="/profile" component={Profile} />
                <Route path="/settings" component={Settings} />
              </Switch>
            </div>
          </BrowserRouter>
        </ApolloProvider>
      ) : (
        <Login />
      )}
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
