import React, { useMemo } from "react";
import Moment from "moment";
import { detect } from "detect-browser";

const SecurityMsg = ({ lastOnline }) => {
  const browserName = useMemo(() => {
    const browser = detect();
    return browser.name
      ? browser.name[0].toUpperCase() + browser.name.slice(1)
      : "";
  }, []);
  return (
    <div className="security-msg">
      <div className="row">
        {lastOnline
          ? `Last connection on
${Moment(lastOnline).format("dddd, MMM Do YYYY, HH:mm")} in Madrid,
          Spain. ${browserName}.`
          : null}
      </div>
    </div>
  );
};

export default SecurityMsg;
