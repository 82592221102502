import React from "react"

const LoginFooter = () => {
  return (
    <footer className="footer_login">
      <p>
        © {new Date().getFullYear()}, Property of
        {` `} LafargeHolcim
      </p>
    </footer>
  )
}

export default LoginFooter
