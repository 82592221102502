import React from "react";

const HomeAppsAdded = ({ data, openApp, removeApp, localUsedApps }) => {
  const remove = (event) => {
    event.stopPropagation();
    if (Object.keys(data).length > 0) {
      const userId = sessionStorage.getItem("userId");
      let applications = [];
      const app = { id: event.target.id };
      for (let i = 0; i < data.User.applications.length; i++) {
        applications.push({ id: data.User.applications[i].id });
      }
      //applications.push(newApp)
      console.log("remove");
      console.log(app, applications);
      let filteredApps = applications.filter(function (value, index, arr) {
        return value.id !== app.id;
      });
      console.log(filteredApps);

      removeApp({
        variables: { identity: userId, applications: filteredApps },
      });
      console.log("antes", localUsedApps);
      delete localUsedApps[event.target.id];
      console.log("despues", localUsedApps);
      sessionStorage.setItem("mostUsedApps1", JSON.stringify(localUsedApps));
    }
  };
  return (
    <div className="l-section">
      <h2 className="l-section__title">My added apps</h2>
      <div className="l-section__desc-wrapper">
        <p className="l-section__desc">
          Add applications to this screen from the App catalog.
        </p>
      </div>
      <ul className="app-list">
        {data &&
          data.User &&
          data.User.applications.map((app) => (
            <li>
              <div
                className="card-content"
                onClick={() => openApp(app.link, app)}
              >
                <lh-card icon="open-in-new" summary={app.name}>
                  <div className="card-content__text">
                    <p>{app.description}</p>
                    <lh-button
                      id={app.id}
                      onClick={remove}
                      style={{ paddingTop: "10px" }}
                    >
                      Remove
                    </lh-button>
                  </div>

                  <ul className="tag-list">
                    {app.category.map((category) => (
                      <li>
                        <div className="tag">{category.name}</div>
                      </li>
                    ))}
                  </ul>
                </lh-card>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default HomeAppsAdded;
