import {
  ApolloClient,
  gql,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from "apollo-boost";
import { setContext } from "@apollo/client/link/context";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_ROLES_ENDPOINT });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = process.env.REACT_APP_ROLES_APIKEY;
  console.log(token);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-api-key": token,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const listRolesMysql = gql`
  query ListRolesMysql($userEmail: String, $envlq: String, $ext_login: String) {
    listRolesMysql(
      userEmail: $userEmail
      envlq: $envlq
      ext_login: $ext_login
    ) {
      ROLE_ID
      ROLE_NAME
    }
  }
`;
